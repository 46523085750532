import { useMutation } from '@apollo/client'

import { GraphQLUtils } from 'utils'

import { ChangeUserRole, ChangeUserRoleVariables, CHANGE_USER_ROLE } from './api'

export const useChangeUserRole = () => {
  const [changeUserRole] = useMutation<ChangeUserRole, ChangeUserRoleVariables>(CHANGE_USER_ROLE, {
    onError: GraphQLUtils.errorHandler,
  })
  return { changeUserRole }
}
