import { useMemo } from 'react'

import { OrganizationFeatures } from 'types'

import { useUser } from './useUser'

export const useFeatures = () => {
  const user = useUser()

  const featureFlags = user?.organizationRole?.organization?.featureFlags

  const featureSetId = user?.organizationRole?.organization?.featureSetId

  const organizationFeatures = useMemo(() => {
    const features: OrganizationFeatures[] = []

    const { crm, subFarmersManagement, supply } = featureFlags ?? {}

    if (crm?.enabled) features.push(OrganizationFeatures.CRM)
    if (subFarmersManagement?.enabled) features.push(OrganizationFeatures.SUB_FARMERS_MANAGEMENT)
    if (supply?.enabled) features.push(OrganizationFeatures.SUPPLY)

    return features
  }, [featureFlags])

  return {
    featureFlags: {
      general: featureFlags?.general,
      crm: featureFlags?.crm,
      subFarmersManagement: featureFlags?.subFarmersManagement,
      supply: featureFlags?.supply,
    },
    featureSetId,
    organizationFeatures,
    isCommercialNameEnabled: user?.organizationRole?.organization.isCommercialNameEnabled,
  }
}
