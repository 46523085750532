import { useMemo } from 'react'

import { useFeatures, useUser } from 'hooks'

import { useOrganizationUsers } from './useOrganizationUsers'
import { UserTableData } from '../types'

export const useSettings = () => {
  const user = useUser()
  const { organizationFeatures } = useFeatures()

  const { invitations, users, loading: usersLoading } = useOrganizationUsers()

  const organization = useMemo(() => {
    return {
      id: user?.organizationRole?.organization.id,
      name: user?.organizationRole?.organization.name,
      logo: user?.organizationRole?.organization.logo ?? undefined,
      features: organizationFeatures,
    }
  }, [organizationFeatures, user])

  const usersCardData = useMemo(() => {
    if (!user || !users) return []

    const allUsers = users.filter(orgUser => orgUser.user.id !== user.id)

    const currentUser: UserTableData = {
      id: user.id,
      email: user.email,
      fullname: `${user.name} ${user.lastName}`,
      role: user.organizationRole!.role!,
      profilePicture: user.profilePicture ?? undefined,
      currentUser: true,
    }
    const organizationUsers: UserTableData[] =
      allUsers.map(orgUser => ({
        id: orgUser.user.id,
        email: orgUser.user.email,
        fullname: `${orgUser.user.name} ${orgUser.user.lastName}`,
        role: orgUser.role,
        regionId: orgUser.regionId ?? undefined,
        profilePicture: orgUser.user.profilePicture ?? undefined,
      })) ?? []

    const invitedUsers: UserTableData[] =
      invitations?.map(invitedUser => ({
        id: invitedUser.id,
        email: invitedUser.email,
        fullname: 'Usuario sin cuenta en Kuna',
        role: invitedUser.role,
        regionId: invitedUser.regionId ?? undefined,
        isInvitation: true,
      })) ?? []

    return [currentUser, ...invitedUsers, ...organizationUsers]
  }, [invitations, user, users])

  return { organization, usersCardData, usersLoading }
}
