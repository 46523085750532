import { Col, Row } from 'antd'

import {
  CardSection,
  ProductionTypeSelect,
  Loader,
  SimpleLotStatusSelect,
  GrainTypeSelect,
  DeliveryConditionSelect,
} from 'components'
import { DeliveryCondition, GrainType, ProductionType, RiceLotStatus, SelectValueType } from 'types'

import {
  Chart,
  ChartContainer,
  LoaderContainer,
  ContainerRow,
  ColContainer,
  FortnightSelect,
  Collapse,
  CollapseContainer,
  TitleContainer,
} from './components'
import { ChartData, FortnightOptions } from './types'
import { HarvestedCalendarLots } from '../../hooks/useHarvestedCalendar'

interface Props {
  harvestChartData: ChartData[]
  areaChartData: ChartData[]
  harvestByStatus: ChartData[]
  loading?: boolean
  onStatusChange: (lotStatus: RiceLotStatus) => void
  selectedStatus?: RiceLotStatus
  selectedGrainType: SelectValueType<GrainType>
  selectedDeliveryCondition: SelectValueType<DeliveryCondition>
  selectedProductionType: SelectValueType<ProductionType>
  onGrainTypeChange: (grainType: SelectValueType<GrainType>) => void
  onDeliveryConditionChange: (deliveryCondition: SelectValueType<DeliveryCondition>) => void
  onProductionTypeChange: (productionType: SelectValueType<ProductionType>) => void
  harvestOrganizationStatsLoading: boolean
  organizationStatsByStatusLoading: boolean
  fortnightSelectOptions: FortnightOptions[]
  selectedFortnight?: string
  onFortnightChange: (fortnight: string) => void
  harvestedCalendarData?: HarvestedCalendarLots[]
  isHarvestedCalendarLoading: boolean
}

export const ChartsCard: React.FC<Props> = ({
  loading,
  onStatusChange,
  selectedStatus,
  harvestChartData,
  areaChartData,
  harvestByStatus,
  selectedGrainType,
  selectedDeliveryCondition,
  selectedProductionType,
  onGrainTypeChange,
  onDeliveryConditionChange,
  onProductionTypeChange,
  harvestOrganizationStatsLoading,
  organizationStatsByStatusLoading,
  fortnightSelectOptions,
  selectedFortnight,
  onFortnightChange,
  harvestedCalendarData,
  isHarvestedCalendarLoading,
}) => {
  const isHarvestStatusSelected = selectedStatus === RiceLotStatus.HARVEST

  return (
    <CardSection title="Superficie y toneladas por estado">
      <Row gutter={[0, 45]}>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            <Col>
              <ProductionTypeSelect
                onChange={onProductionTypeChange}
                value={selectedProductionType}
              />
            </Col>
            <Col>
              <GrainTypeSelect onChange={onGrainTypeChange} value={selectedGrainType} />
            </Col>
            <Col>
              <DeliveryConditionSelect
                onChange={onDeliveryConditionChange}
                value={selectedDeliveryCondition}
              />
            </Col>
          </Row>
        </Col>
        <Col flex={1}>
          {loading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} xl={6}>
                    <ColContainer>
                      {harvestOrganizationStatsLoading ? (
                        <LoaderContainer>
                          <Loader />
                        </LoaderContainer>
                      ) : (
                        <>
                          <ContainerRow align="middle" justify="center">
                            Superficie (ha)
                          </ContainerRow>
                          <ChartContainer flex="auto">
                            <Chart data={areaChartData} />
                          </ChartContainer>
                        </>
                      )}
                    </ColContainer>
                  </Col>
                  <Col xs={24} xl={9}>
                    <ColContainer>
                      {organizationStatsByStatusLoading ? (
                        <LoaderContainer>
                          <Loader />
                        </LoaderContainer>
                      ) : (
                        <>
                          <ContainerRow align="middle" justify="space-evenly">
                            <SimpleLotStatusSelect
                              onChange={onStatusChange}
                              value={selectedStatus}
                            />
                            Superficie (ha)
                          </ContainerRow>
                          <ChartContainer>
                            <Chart
                              data={harvestByStatus}
                              selectedFortnight={
                                isHarvestStatusSelected ? selectedFortnight : undefined
                              }
                            />
                          </ChartContainer>
                        </>
                      )}
                    </ColContainer>
                  </Col>

                  <Col xs={24} xl={9}>
                    <ColContainer>
                      {harvestOrganizationStatsLoading ? (
                        <LoaderContainer>
                          <Loader />
                        </LoaderContainer>
                      ) : (
                        <>
                          <ContainerRow align="middle" justify="center">
                            Consecha (t)
                          </ContainerRow>
                          <ChartContainer>
                            <Chart data={harvestChartData} selectedFortnight={selectedFortnight} />
                          </ChartContainer>
                        </>
                      )}
                    </ColContainer>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <ColContainer>
                  <>
                    <TitleContainer>
                      <ContainerRow align="middle">
                        <Col span={5}>
                          <FortnightSelect
                            options={fortnightSelectOptions}
                            value={selectedFortnight}
                            onChange={onFortnightChange}
                          />
                        </Col>
                        <Col span={5} push={5}>
                          Calendario de cosecha
                        </Col>
                      </ContainerRow>
                    </TitleContainer>
                    {isHarvestedCalendarLoading || harvestOrganizationStatsLoading ? (
                      <LoaderContainer>
                        <Loader />
                      </LoaderContainer>
                    ) : (
                      <CollapseContainer>
                        <Collapse data={harvestedCalendarData} />
                      </CollapseContainer>
                    )}
                  </>
                </ColContainer>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </CardSection>
  )
}
