import { gql } from '@apollo/client'

export type { ChangeUserRole, ChangeUserRoleVariables } from './types/ChangeUserRole'

export const CHANGE_USER_ROLE = gql`
  mutation ChangeUserRole($userId: Int!, $role: UserOrganizationRole!) {
    changeOrgUserRole(userId: $userId, role: $role) {
      orgUser {
        id
        user {
          id
          email
        }
        role
        regionId
      }
    }
  }
`
