import { Space, Typography } from 'antd'
import styled from 'styled-components'

const { Text: AntText } = Typography

export const Text = styled(AntText)`
  font-weight: 700;
`

export const InputTitle = styled(AntText)`
  font-size: 13px;
`

export const InputContainer = styled(Space).attrs({
  direction: 'vertical',
  size: 5,
})``
